import CourseCard from "./courses/CourseCard";

import css from "./Upcoming.module.css";

export default function Upcoming() {
	let upcomingCourses = [
		{
			key: 1,
			image: "/img/A320ceo.jpg",
			title: "Airbus A320 series (CFM56, V2500) Cat. B1.1/B2",
			date: "Start on March 31, 2025",
			time: "17:00 EET",
			duration: "26 days + 5 days SS",
			location: "Online (Zoom)",
			price: "on request",
			practical: "Sofia, Bulgaria",
			form: "https://forms.gle/8dNYAQUTxuRJujyM7",
		},
	];
	
	let coursesOnRequest = [
		{
			key: 1,
			image: "/img/A320ceo.jpg",
			title: "Airbus A320 series (CFM56, V2500) Cat. B1.1/B2",
			date: "",
			time: "17:00 EET",
			duration: "26 days + 5 days SS",
			location: "Online (Zoom)",
			price: "on request",
			practical: "Sofia, Bulgaria",
			form: "mailto:pca@pc-acad.com",
		},
		{
			key: 2,
			image: "/img/A320neo.jpg",
			title: "Differences from A320(any engine) to A320(CFM LEAP, PW1100G)",
			date: "",
			time: "17:00 EET",
			duration: "5 days",
			location: "Online (Zoom)",
			price: "on request",
			practical: "Sofia, Bulgaria",
			// form: "https://forms.gle/9MnKRRwfoMuYdcKf7",
			form: "mailto:pca@pc-acad.com",
		},
		{
			key: 3,
			image: "/img/A330ceo.png",
			title: "Differences from A320(any engine) to A330(GE CF6, RR Trent 700)",
			date: "",
			time: "17:00 EET",
			duration: "20 days + 5 days SS",
			location: "Online (Zoom)",
			price: "on request",
			practical: "Sofia, Bulgaria (GE CF6)",
			form: "mailto:pca@pc-acad.com",
		},
		{
			key: 4,
			image: "/img/B737ng.jpg",
			title: "Boeing 737-600/700/800/900 (CFM56) Cat. B1.1/B2",
			date: "",
			time: "17:00 EET",
			duration: "29 days",
			location: "Online (Zoom)",
			price: "on request",
			practical: "Sofia, Bulgaria",
			form: "mailto:pca@pc-acad.com",
		},
		{
			key: 5,
			image: "/img/B737max.jpg",
			title: "Diff from B737NG (CFM56) to B737MAX (CFM LEAP) B1/B2",
			date: "",
			time: "17:00 EET",
			duration: "9 days TT + 3 days PT",
			location: "Online (Zoom)",
			price: "on request",
			practical: "TBD",
			form: "mailto:pca@pc-acad.com",
		},
		{
			key: 6,
			image: "/img/A220.jpg",
			title: "(A220) Bombardier BD-500 Series (PW PW1500G) Cat. B1.1/B2",
			date: "",
			time: "17:00 EET",
			duration: "28 days",
			location: "Online (Zoom)",
			price: "on request",
			practical: "Sofia, Bulgaria",
			form: "mailto:pca@pc-acad.com",
		},
		{
			key: 7,
			image: "/img/G200.png",
			title: "Gulfstream G200/Galaxy and G280 Cat. B1.1/B2",
			date: "",
			time: "17:00 EET",
			duration: "21 days + 6 days",
			location: "Online (Zoom)",
			price: "on request",
			practical: "Sofia, Bulgaria",
			form: "mailto:pca@pc-acad.com",
		},

	]

	return (
		<section>
			<div className='container'>
				{/* <!--start heading--> */}
				<div className={css["course-heading"]}>
					<div className={css["sec-heading"]}>
						<h2>Upcoming Courses</h2>
					</div>
				</div>
				{/* <!--end heading--> */}

				<div className={css["flex"]}>
					{upcomingCourses.map((x) => {
						return <CourseCard data={x} />;
					})}
				</div>

				<div className={css["course-heading"]}>
					<div className={css["sec-heading"]}>
						<h2>Courses on request</h2>
					</div>
				</div>
				<div className={css["flex"]}>
					{coursesOnRequest.map((x) => {
						return <CourseCard data={x} />;
					})}
				</div>

				{/* <div className='row'>
					<div className='col-lg-12'>
						<div className='category-btn btn-default text-center'>
							<a href='#'>all courses</a>
						</div>
					</div>
				</div> */}
			</div>
		</section>
	);
}
